<template>
  <div class="confirm_box">
    <div id="header">
      <div @click="$router.go(-1)">
        <van-icon name="arrow-left" size="24" />
      </div>
      <div style="font-size:16px;">确认订单</div>
      <div>
        <van-icon name="arrow-left" size="24" color="rgba(0,0,0,0)" />
      </div>
    </div>
    <div class="choose_is_express">
      <div class="choose-is-express-title">取货方式</div>
      <van-radio-group v-model="radio" direction="horizontal" >
        <van-radio name="1" checked-color="#ee0a24">快递</van-radio>
        <van-radio name="2" checked-color="#ee0a24" style="border-left:1px solid #f7f7f7;">自提</van-radio>
      </van-radio-group>
    </div>
    <div style="padding-bottom:20px;" v-if="isExpress">
      <div class="address_box" @click="$router.push('/address?id=' + userInfo.id)">
        <div class="address_icon">
          <van-icon name="location" color="#f5736e" size="30" />
        </div>
        <div class="address_info" v-if="chooseAddress">
          <div>
            {{chooseAddress.name}}
            <span>{{chooseAddress.tel}}</span>
          </div>
          <div>{{chooseAddress.address}}</div>
        </div>
        <div class="address_info" v-else>
          <div>新增收货地址</div>
        </div>
        <div style="margin-right:-10px;">
          <van-icon name="arrow" size="30" />
        </div>
      </div>
    </div>
    <div style="padding-bottom:20px;" v-else>
      <div class="address_box">
        <div class="address_icon">
          <van-icon name="location" color="#f5736e" size="30" />
        </div>
        <div class="address_info" >
          <div>
            {{zitiInfo.name}}
            <span>{{zitiInfo.mobile}}</span>
          </div>
          <div>{{zitiInfo.address}}</div>
        </div>
        <div style="margin-right:-10px;">
          <van-icon name="arrow" size="30" style="opacity:0;" />
        </div>
      </div>
    </div>
    <div v-for="(item, orderindex) in order" :key="orderindex" style="padding-top:1px;">
      <goods-confirm @handleChangeOrder="handleChangeOrder" :order="item" :orderindex="orderindex"
        style="border-radius:0;"></goods-confirm>
      <div v-if="userInfo.store_info" class="store-stock">
        当前服务前置仓：<span >{{userInfo.store_info.name}}</span>，库存剩余：<span>{{orderStockMap[orderindex].stock}}</span>,
        <span v-if="orderStockMap[orderindex].stock">
          放心购买
        </span>
        <span v-else style="color:var(--fc--);">
          库存不足
        </span>
      </div>
    </div>
    <div>
      <div class="pay_mode">
        <div v-if="false">支付方式</div>
        <div class="mode-item"  @click="wechat = true">
          <div>
        <van-icon style="pointer-events:none;width:1.625rem;height:1.625rem;"  :src="require('../../assets/svg/微信icon.svg')"   tag="embed" type="image/svg+xml" />
          </div>
          <div>微信</div>
          <div class="check_box">
            <van-checkbox v-model="wechat" checked-color="#F5746E"></van-checkbox>
          </div>
        </div>
        <div class="mode-item" @click="zhifubao = true">
          <div>
        <van-icon style="pointer-events:none;width:1.625rem;height:1.625rem;"  :src="require('../../assets/svg/支付宝icon.svg')"   tag="embed" type="image/svg+xml" />
          </div>
          <div>支付宝</div>
          <div class="check_box">
            <van-checkbox v-model="zhifubao" checked-color="#F5746E"></van-checkbox>
          </div>
        </div>
      </div>
    </div>
    <div class="buy_bar">
      <div>
        <span >实际支付：</span>
        <span >￥{{allPrice}}</span>
      </div>
      <div @click="toPay">
        <div class="pay_btn">确认支付</div>
      </div>
    </div>
  </div>
</template>

<script>
  import MyBtn from "@/components/common/MyBtn.vue";
  import GoodsConfirm from "@/components/goods/GoodsConfirm.vue";
  export default {
    components: {
      MyBtn,
      GoodsConfirm
    },
    props:['userInfo'],
    data() {
      return {
        value: 1,
        zhifubao: false,
        wechat: true,
        price: 0,
        order: [],
        //userInfo: JSON.parse(window.sessionStorage.getItem('userInfo')),
        list: [],
        chooseAddress: {},
        addressId: '',
        allCount: 0,
        allPrice: 0,
        radio:'1',
        orderStockMap:{}
      };
    },
    created() {
      let addressId = window.sessionStorage.getItem("addressId");
      if (!addressId) {
        window.sessionStorage.setItem("addressId", '');
      }
      if (!this.$bus.$data.order || !this.$bus.$data.userInfo) {
        this.$bus.$data.order = JSON.parse(
          window.sessionStorage.getItem("order")
        );
        this.$bus.$data.userInfo = JSON.parse(
          window.sessionStorage.getItem("userInfo")
        );
      }
      this.$bus.$on("change-address", this.changeAddress);
      this.order = this.$bus.$data.order;
      this.orderStockMap = this.order.map((x)=>({id:x.id,stock:0}))
      //this.userInfo = this.$bus.$data.userInfo;
      this.addressId = window.sessionStorage.getItem("addressId");
      window.sessionStorage.setItem("order", JSON.stringify(this.order));
      // this.$bus.$watch('order',(val)=>{
      //   console.log(111)
      //   window.sessionStorage.setItem('order',JSON.stringify(val))
      // },{
      //   deep:true
      // })
      this.getAddressList();
      this.getAllPriceAndAllCount();
    },
    mounted(){
      this.getGoodsStockList()
    },
    activated() {},
    deactivated() {

    },
    beforeDestroy() {

    },
    watch: {
      wechat(val) {
        if (val) {
          this.zhifubao = false;
        } else if (!this.zhifubao) {
          this.wechat = true;
        }
      },
      zhifubao(val) {
        if (val) {
          this.wechat = false;
        } else if (!this.wechat) {
          this.zhifubao = true;
        }
      },

    },
    computed:{
      isExpress(){
        return this.radio == 1 ? true : false; 
      },
      zitiInfo(){
        let {province,city,area,address,mobile,name} = this.userInfo.store_info
        return {
          address:province+city+area+address,
          mobile,
          name
        }
      },
      payBtnDisabled(){
        let result = false
        this.order.forEach((x,index) =>{
          console.log(x.count,this.orderStockMap[index].stock,'库存')
          if(x.count > this.orderStockMap[index].stock){
            return result = true
          }
        })
        return result
      }
    },
    methods: {
      handleChangeOrder(order, index) {
        this.$bus.$data.order[index] = order;
        window.sessionStorage.setItem(
          "order",
          JSON.stringify(this.$bus.$data.order)
        );
        if (window.location.href.search("fromcart") !== -1) {
          let myCart = JSON.parse(window.sessionStorage.getItem("myCart"));
          myCart.items[index] = order;
          window.sessionStorage.setItem("myCart", JSON.stringify(myCart));
        }
        this.getAllPriceAndAllCount();
      },
      getAllPriceAndAllCount() {
        let allPrice = 0;
        let allCount = 0;
        this.order.forEach(x => {
          allPrice += Number(x.price);
          allCount += Number(x.count);
        });
        this.allPrice = allPrice;
        this.allCount = allCount;
      },
      async getStoreStock(goodsId) {
        let {
          data: res
        } = await this.$http.post("/api/getGoodsStock", {
          store_id:this.userInfo?.store_id,
          goods_id:goodsId,
        });
        if (res.statusCode !== 0) {
          return 
        }
        if(res.data instanceof Array){
          return null
        }
        return res.data
      },
      async getGoodsStockList(){
        let orderList = this.order
        for  (let [k,item] of Object.entries(orderList)){
          let data = await this.getStoreStock(item.id)
          if(data){
            this.orderStockMap[k].stock = data.goods_stock
          } 
        }
        
      },
      async getAddressList() {
        let {
          data: res
        } = await this.$http.post("api/getAddressList", {
          id: this.userInfo.id
        });
        if (res.statusCode !== 0) {
          return this.$toast.fail(res.msg);
        }
        this.list = res.data.map(i => {
          i.address = i.province + i.city + i.county + i.address;
          i.is_default == "1" ? (i.isDefault = true) : (i.isDefault = false);
          return i;
        });
        this.getAddressById(res.data);
      },
      getAddressById(addressList) {
        let address = addressList.filter(x => {
          if (x.id == this.addressId) {
            return true;
          }
          return false;
        });
        if (!address[0]) {
          let address = addressList.filter(x => {
            if (x.isDefault) {
              this.addressId = x.id;
              this.changeAddress(x.id);
              return true;
            }
            return false;
          });
          return (this.chooseAddress = address[0]);
        }
        this.chooseAddress = address[0];
        this.changeAddress(this.chooseAddress.id);
      },
      changeAddress(addressId) {
        window.sessionStorage.setItem("addressId", addressId);
      },
      async toPay() {
        // if(!this.isExpress){
        //   return this.$toast.fail('暂未接通')
        // }
        if(this.payBtnDisabled){
          return this.$toast.fail('当前商品库存不足')
        }
        
        this.createOrder();
        //let { data: res } = await $http.post("");
      },
      async createOrder() {
        let user_id = this.$getUser()?.id;
        let address_id = window.sessionStorage.getItem("addressId");
        let orderItems = JSON.parse(window.sessionStorage.getItem("order"));
        if (!address_id && this.radio == '1') {
          return this.$toast.fail("未选择地址");
        }
        if (!this.wechat && !this.zhifubao) {
          return this.$toast.fail("未选择支付方式");
        }
        let items = [];
        orderItems.forEach(x => {
          items.push({
            goods_id: x.id,
            count: x.count
          });
        });
        if (items.length == 0) {
          return this.$toast.fail("订单项为空");
        }
        let orderForm = {
          user_id: user_id,
          address_id: address_id,
          store_id:this.userInfo.store_id,
          items: items,
          goods_recieve_type: this.isExpress ? '1' : '2'
        };
        if (this.wechat) {
          orderForm.pay_type = 1
          this.wxPay(orderForm)
        }
        if (this.zhifubao) {
          orderForm.pay_type = 2
          this.zfbPay(orderForm)
        }
        console.log(orderForm);

        //this.wxPay(res.data.order_id);
      },
      async wxPay(orderForm) {
        let {
          data: res
        } = await this.$http.post("api/createOrder", orderForm);
        if (res.statusCode !== 0) {
          return this.$toast.fail(res.msg);
        }
        // return console.log(res)


        // 虚拟支付
       
        // let pay_res = await this.changeOrderStatus(res.data.orderId)
        // if(pay_res.statusCode !== 0){
        //   return this.$toast.fail(pay_res.msg);
        // }
        // return this.$router.push('/paystatus/' + res.data.orderNo +'?fakePay=1')
        
        // 真实支付
        let url = window.location
        let redirect_url = url.origin + '/' + url.search + '#/paystatus/' + res.data.orderNo
        //+ '&redirect_url=' + encodeURI(redirect_url)
        let type = this.isExpress ? '1' : '2'
        await this.$router.push('/paystatus/' + res.data.orderNo +'?goods_recieve_type=' + type + '&orderId=' + res.data.orderId)
        

        // 创建form拉起支付
        let form = document.createElement('form')
        document.body.appendChild(form)
        form.method = 'post'
        form.action = res.data.mwebUrl
        form.submit()
        document.body.removeChild(form)
        //return (window.location.href = res.data.mwebUrl);
      },
      async zfbPay(orderForm) {
        let {
          data: res
        } = await this.$http.post("api/createOrder", orderForm);
        if (res.statusCode !== 0) {
          return this.$toast.fail(res.msg);
        }

        // 虚拟支付
        /*
        let pay_res = await this.changeOrderStatus(res.data.orderId)
        if(pay_res.statusCode !== 0){
          return this.$toast.fail(pay_res.msg);
        }

        this.$router.push('/paystatus/' + res.data.orderNo + '?fakePay=1')
        //+ '&redirect_url=' + encodeURI(redirect_url)
        return 
        */
        //this.$router.push('/paystatus/' + res.data.orderNo)
        let type = this.isExpress ? '1' : '2'
        await this.$router.push('/paystatus/' + res.data.orderNo +'?goods_recieve_type=' + type + '&orderId=' + res.data.orderId)
         // 创建form拉起支付
        let form = document.createElement('form')
        document.body.appendChild(form)
        form.method = 'post'
        form.action = res.data.mwebUrl
        form.submit()
        document.body.removeChild(form)
        //return (window.location.href = res.data.mwebUrl );
      },
      pollingOrder(order_no) {
        //window.alert(order_no);
        let count = 0;
        window.timer = setInterval(async () => {
          if (count >= 999) {
            return clearInterval(window.timer);
          }
          let res = await this.getOrderStatus(order_no);
          this.$toast.fail(res.statusCode);
          if (res.statusCode == 0) {
            clearInterval(window.timer);
            this.$router.push("/paystatus/success");
          }
          count++;
        }, 5000);
      },
      async getOrderStatus(order_no) {
        let {
          data: res
        } = await this.$http.post("/api/payment/paid", {
          out_trade_no: order_no
        });
        return res;
      },
      // 虚拟支付改变支付状态
      async changeOrderStatus(order_id) {
        let {
          data: res
        } = await this.$http.post("/api/pay", {
          order_id: order_id
        });
        return res;
      },
    }
  };
</script>

<style lang="less" scoped>
  // /deep/.van-stepper--round .van-stepper__plus{
  //   background-color:#D9D9D9 !important;
  // }
  // /deep/.van-stepper--round .van-stepper__minus{
  //   background-color:#D9D9D9 !important;
  //   border: none;
  // }
  .store-stock{
    text-align: left;
    padding: 0 20px 10px 20px;
    background-color:#fff;
    color: #666;
    font-size: 12px;
    span{
      color: var(--fc--);
    }
  }
  .choose_is_express {
    display:flex;
    justify-content:space-between;
    .van-radio-group{
      
      padding:20px 8px;
      width:45% !important;
      justify-content:space-between;
    }
    .choose-is-express-title{
      display:flex;
      align-items:center;
      padding-left:20px;
    }
    >div{
      background-color:#fff;
      width:60%;
    }
  }

  .pay_btn {
    width: 6.875rem;
    height: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    letter-spacing: 1.6px;
    background-color: var(--fc--);
    color: #fff;
    border-radius: 2rem;
    font-size: 1rem;
  }

  .confirm_box {
    padding-top: 5.5rem;
    width: 100%;
    height: calc(100vh);
    background-color: #f9f9f9;
    position: relative;
    padding-bottom: 50px;

    >div {
      padding: 20px 0;
      padding-bottom: 0;
    }

    .address_box {
      background-color: #fff;
      padding: 20px 20px;
      text-align: left;
      display: flex;
      align-items: center;

      .address_info {
        margin-left: 10px;
        width: 100%;

        >div {
          padding: 2px;
          color: #333;

          span {
            margin-left: 5px;
            font-size: 14px;
            color: #999;
          }
        }
      }
    }

    .pay_mode {
      text-align: left;
      // padding: 20px;
      background-color: #fff;
      .mode-item:first-child{
        padding: 1.3125rem 1.1875rem .75rem 1.1875rem;
        border: 1px solid rgba(242, 243, 245, 1);
      }
      .mode-item:last-child{
        padding: .75rem 1.1875rem  1.3125rem 1.1875rem;
        border: 1px solid rgba(242, 243, 245, 1);
      }
      .mode-item {
        // margin-top: 30px;
        font-size: 15px;
        display: flex;
        align-items: center;
        position: relative;

        >div {
          display: flex;
          align-items: center;
          margin-left: 10px;
        }

        div:first-child {
          margin-left: 0;
        }

        .check_box {
          position: absolute;
          right: 1.1875rem;
        }
      }
    }

    .shadow {
      border-radius: 5px;
      box-shadow: 0 0 5px 5px #eee;
    }

    .buy_bar {
      position: fixed;
      z-index: 444;
      bottom: 0;
      left: 0;
      height: 3.75rem;
      width: 100%;
      background-color: #f3f3f3;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding: 0 20px;
      >div:first-child{
        font-size: 1rem;
        letter-spacing: 0.4px;
        color: var(--fc--);
        margin-right: .9375rem;
      }
    }
  }
</style>
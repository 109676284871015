<template>
  <div class="goods_pre_box">
    
    <div class="img_pre">
      <div class="goods_img">
        <img :src="order.images" alt style="width:100%;height:100%;object-fit:cover;" />
      </div>
    </div>
    <div class="msg_pre">
      <div class="msg_title">
        <div>{{order.title}}</div>
        <div style="color:var(--fc--);font-size:16px;position:relative;">￥{{order.original_price}}
        </div>
      </div>
      <div class="goods_des">
        <div>颜色:金色</div>
        <div>x{{value}}</div>
      </div>
      <div class="card_bottom">
        <div>
          ￥{{order.price}}
        </div>
        <van-stepper v-model="value" theme="round" button-size="18" disable-input />
      </div>
    </div>
  </div>
</template>

<script>
import MyBtn from "@/components/common/MyBtn.vue";
export default {
  props: ["order","orderindex"],
  components: {
    MyBtn
  },

  data() {
    return {
      value: 1,
      url:''
    };
  },
  created() {
    this.value = this.order.count
    this.url = window.location.href
  },
  watch: {
    value(val) {
      let order = this.order
      order.count = val
      order.price = order.original_price * val
      this.$emit("handleChangeOrder", order, this.orderindex);
      this.$emit("handleChangeCart",order,this.orderindex)
    }
  },
  computed: {
  },
  methods: {}
};
</script>

<style lang="less" scoped>
.goods_pre_box {
  padding: 20px;
  display: flex;
  border-radius: 5px;
  background-color: #fff;
  position: relative;
  .goods_des{
    display: flex;
    justify-content: space-between;
    font-size: 10px;
    color: #999;
    margin-top: -2em;
  }
  .img_pre {
    flex-shrink: 0;
    width: 100px;
    height: 100px;
    .goods_img {
      border-radius: 10px;
      width: 100%;
      height: 100%;
      background-color: #eee;
    }
    
  }
  .msg_pre {
    width: 100%;
    padding-left:20px;
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .msg_title{
      display: flex;
      justify-content: space-between;
      align-items: center;
      div{
        display: flex;
        align-items:center;
      }
    }
    .card_bottom {
      display: flex;
      justify-content: space-between;
      > div {
        font-size: 18px;
        color: var(--fc--);
      }
    }
  }
}
</style>